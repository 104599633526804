// Do not forget to update getting-started/theming.md!
:root {
    // Custom variable values only support SassScript inside `#{}`.
    @each $color, $value in $theme-custom {
      --iq-#{$color}: #{$value};
    }

    @each $color, $value in $dark-theme-custom {
      --iq-#{$color}: #{$value};
    }
}
  