@keyframes shadowDots {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 177, 255, 0.2);
	}
	100% {
		box-shadow: 0 0 0 15px rgba(0, 177, 255, 0);
	}
}
@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 177, 255, 0.2);
	}
	100% {
		box-shadow: 0 0 0 15px rgba(0, 177, 255, 0);
	}
}
@keyframes shadow-pulse-dots-danger {
	0% {
		box-shadow: 0 0 0 0px rgba(255, 155, 138, 0.2);
	}
	100% {
		box-shadow: 0 0 0 15px rgba(255, 155, 138, 0);
	}
}
@keyframes shadow-pulse-danger {
	0% {
		box-shadow: 0 0 0 0px rgba(255, 155, 138, 0.2);
	}
	100% {
		box-shadow: 0 0 0 15px rgba(255, 155, 138, 0);
	}
}
@-webkit-keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		@include opacity-mixin(0);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		@include opacity-mixin(1);
	}
}
@keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		@include opacity-mixin(0);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		@include opacity-mixin(1);
	}
}

.iq-top-navbar {
	background: $white;
	padding: 0 30px 0 30px;
	min-height: 73px;
	position: fixed;
	top: 0;
	left: auto;
	right: 0;
	width: calc(100% - 260px);
	display: inline-block;
	z-index: 99;
	margin: 0;
	@if $enable-transitions == true {
		@include transition-mixin(all 0.3s ease-in-out);
	}
	@if $enable-shadows == true {
		@include shadow-mixin($navbar-box-shadow);
	}
	.iq-navbar-custom {
		.navbar-breadcrumb {
			display: block;
			margin-left: 0px;
			padding: 20px 0;
		}
		nav{
			.navbar-collapse{
				ul{
					li{
						.list-group-flush{
							border-radius: 5px;
						}
					}
				}
			}
		}
	}
	.breadcrumb {
		.breadcrumb-item.active {
			color: $body-text;
		}
		background: transparent;
		padding: 0;
		margin: 0;
		li {
			list-style-type: none;
			font-size: 14px;
			a {
				text-decoration: none;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.navbar-expand-lg {
		justify-content: space-between !important;
	}
	.dropdown-menu {
		position: absolute;
		-webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
	.iq-sub-dropdown {
		width: 330px;
		position: absolute;
		top: 100%;
		right: 0;
		left: auto;
		border: none;
		-webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		background-color: $white;
		@if $enable-transitions == true {
			@include transition-mixin(all 0.3s ease-in-out);
		}
		padding: 0;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius);
		}
		@if $enable-shadows == true {
			@include shadow-mixin($dropdown-box-shadow);
		}
		@if $enable-transitions == true {
			@include transition-mixin(all 0.3s ease-in-out);
		}
		.iq-card-body {
			div {
				@if $enable-rounded == true {
					@include radius-mixin($border-radius-top);
				}
			}
		}
	}
	.lang {
		.iq-sub-dropdown {
			width: auto;
		}
	}
	.navbar-brand{
		padding: 0;
	}
}
body.sidebar-main {
	.iq-top-navbar {
		width: calc(100% - 80px);
	}
}
.iq-top-navbar.fixed-header {
	margin: 0;
	right: 0;
	width: calc(100% - 330px);
	@include radius-mixin(0);
	@if $enable-transitions == true {
		@include transition-mixin(all 0.3s ease-in-out);
	}
}
.navbar-breadcrumb {
	.breadcrumb {
		padding: 0;
		background: none;
		margin-top: 5px;
	}
}
.breadcrumb-item+.breadcrumb-item {
	&::before {
		content: #{$breadcrumb-icon};
		vertical-align: sub;
		color: $body-text;
	}
}
.navbar-list {
	margin: 0;
	padding: 0;
	float: right;
	li {
		float: left;
		list-style-type: none;
		position: relative;
		>.dropdown {
			.dropdown-menu {
				-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
				animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
				@include transform-mixin( translateY(100%));
			}
			line-height: 0px;
			cursor: pointer;
		}
		.dropdown-toggle {
			&::after {
				display: none;
			}
		}
		>a.language-title {
			font-size: 16px;
		}
		>a {
			color: $body-text;
			padding: 0 10px;
			display: block;
			&.search-toggle {
				line-height: 73px;
			}
			>img {
				width: 50px;
				height: 50px;
				min-width: 50px;
			}
		}
		a {
			&:hover {
				text-decoration: none;
			}
		}
		.caption {
			line-height: 16px;
			p {
				color: $body-text;
				font-size: 12px;
			}
		}
		.iq-sub-dropdown {
			.iq-sub-card {
				&:last-child {
					padding-bottom: 0;
				}
				font-size: inherit;
				padding-bottom: 18px;
				line-height: normal;
				color: inherit;
				display: inline-block;
				width: 100%;
			}
			.iq-sub-card.iq-sub-card-big {
				width: auto;
			}
		}
	}
	li.iq-nav-user {
		display: flex;
		align-items: flex-start;
		span {
			flex: 1;
			font-size: 14px;
		}
	}
	.caption-content{
		line-height: normal;
	}
}
.menu-tag {
	position: absolute;
	background: $body-text;
	color: $white;
	display: inline-block;
	top: 18px;
	left: 18px;
	font-size: 12px;
	border-radius: 90px;
	line-height: normal;
	height: 18px;
	width: 18px;
	line-height: 18px;
	text-align: center;
	display: inline-block;
}
.iq-breadcrumb {
	display: none;
}
.toggle-cart-info {
	img {
		width: 50px;
	}
}
.nav-item {
	span.dots {
		height: 6px;
		width: 6px;
		font-size: 0;
		text-align: center;
		padding: 0;
		position: absolute;
		top: 18px;
		right: 3px;
		border: 0;
		animation: shadowDots 1s infinite;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-circle);
		}
	}
	span.count-mail {
		height: 6px;
		width: 5px;
		font-size: 10px;
		text-align: center;
		line-height: 15px;
		padding: 0;
		position: absolute;
		top: 18px;
		right: 3px;
		border: 0;
		animation: shadow-pulse 1s infinite;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-circle);
		}
	}
	.badge.count-cart {
		height: 15px;
		width: 15px;
		font-size: 10px;
		text-align: center;
		line-height: 15px;
		padding: 0;
		position: absolute;
		top: 13px;
		right: 0px;
	}
}
.top-nav-bottom {
	margin-left: 260px;
	border-top: 1px solid $primary-light;
}
.topnav-toolbar {
	li {
		a {
			display: block;
			padding: 10px 15px;
			text-transform: capitalize;
			color: $secondary;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius);
			}
		}
	}
	li.date-today {
		background: $light;
	}
	li.active {
		a {
			background: $primary-light;
			color: $primary;
		}
	}
	ul {
		li {
			list-style: none;
			padding: 13px 0;
		}
	}
}
.iq-search-bar 
{
	.searchbox {
		width: 400px;
		position: relative;
	}
	.search-input {
		width: 100%;
		height: 40px;
		padding: 5px 15px 5px 40px;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-normal);
		}
		border: 1px solid $border-color;
		background-color: $body-bg;
	}
	.search-link {
		position: absolute;
		left: 10px;
		top: 11px;
	}

	
}
.iq-navbar-logo {
	padding: 0;
	width: auto;
	position: relative;
	background: $white;
	@if $enable-transitions == true {
		@include transition-mixin(all 0.3s ease-in-out);
	}
	background: transparent;
	a {
		display: flex;
		align-items: center;
		span {
			margin-left: 10px;
			font-size: 28px;
			line-height: 26px;
			font-weight: 500;
			color: $dark;
			text-transform: capitalize;
			flex: 1;
			@if $enable-transitions == true {
				@include transition-mixin(all 0.3s ease-in-out);
			}
		}
		&:hover {
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
	}
	img {
		height: 40px;
	}
}
.iq-sub-dropdown.iq-sub-dropdown-large {
	width: 500px;
}
.cust-title {
	border-bottom: 1px solid $border-color;
}
.crt-img {
	background: $body-bg;
	padding: 10px;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius);
	}
}
.cust-dd {
	.btn {
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-bottom);
		}
	}
}
.dd-icon {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 40px;
	height: 40px;
	background: $primary-dark;
	color: $white;
	border-radius: 0 0 0 10px;
	text-align: center;
	line-height: 40px;
	@if $enable-transitions == true {
		@include transition-mixin(all 0.3s ease-in-out);
	}
	display: flex;
	align-items: center;
	justify-content: center;
}
.right-ic {
	&:hover {
		.dd-icon {
			background: $primary;
		}
	}
	@if $enable-rounded == true {
		@include radius-mixin($border-radius-bottom);
	}
	overflow: hidden;
	border: none !important;
}
.iq-show {
	.iq-sub-dropdown {
		display: inline-block;
	}
}
.iq-sub-dropdown {
	.iq-card-icon {
		width: 45px;
		height: 45px;
		line-height: 40px;
		font-size: 22px;
	}
}
.show-data {
	.iq-user-dropdown {
		@include opacity-mixin(1);
		visibility: visible;
		@include transform-mixin( translateX(0));
		@if $enable-transitions == true {
			@include transition-mixin(all 0.3s ease-in-out);
		}
	}
}
.iq-card-icon-small {
	height: 40px;
	width: 40px;
	min-width: 40px;
	display: inline-block;
	line-height: 40px;
	text-align: center;
	font-size: 22px;
}
.profile-details {
	>a {
		margin-bottom: 15px;
		display: block;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-normal);
		}
	}
}
.data-scrollbar {
	height: 100vh;
}

.navbar-toggler{
	outline: none;
}




@media(min-width:1300px) {
	.iq-navbar-logo {
		display: none !important;
		>a {
			display: none;
		}
	}
	.menu-horizontal {
	.iq-sidebar-menu {
		.side-menu {
			li {
				a.collapsed {
					pointer-events: none;
				}
			}
		}
	}
}
}
@media(max-width:1199px) {
	.iq-search-bar {
		.searchbox {
			width: 320px;
		}
	}
}
@media(max-width:1299px) {
	.iq-top-navbar {
		width: 100%;
		.iq-search-bar.device-search {
			margin-left: 20px;
		}
	}
	body.sidebar-main {
		.iq-top-navbar {
			width: 100%;
		}
	}

}
@media(min-width:992px) {
	
	.search-content {
		display: none;
	}
}
@media(max-width:991px) {
	.user-name{
		display: none;
	}
	.device-search {
		display: none;
	}
	.navbar-collapse {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background: $white;
		padding: 0px 15px;
		box-shadow: 0 20px 30px 0px rgba(0,0,0,.1);
		@if $enable-rounded == true {
			@include radius-mixin($border-radius);
		}
		@if $enable-shadows == true {
			@include shadow-mixin($box-shadow);
		}
	}
	.navbar-nav.navbar-list {
		display: inline-block;
	}
	.iq-top-navbar {
		padding:16px 15px!important;		
		line-height:normal;	
		min-height: unset;
		.navbar-toggler {
			color: $primary-dark;
			background: transparent;
			border: none;
			padding: 0px 0 0;
			border-radius: 5px;
			font-size: 30px;
		}
		.iq-navbar-logo {
			img {
				height: 40px;
			}
		}
		.iq-search-bar{
			line-height: normal;
		}
	}
	.iq-search-bar {
		.searchbox{
			width: 100%;
		}
	}


}
@media (max-width: 991px) {
	.navbar-list {
		li {
			& > a {
				.svg-icon {
					color: $secondary !important;
				}
			}
		}
	}
}
@media(max-width:767px) {
	.iq-top-navbar {
		.lang {
			.iq-sub-dropdown {
				width: 150px;
			}
		}

	}
}
@media(max-width:575px) {
    .search-toggle {
		position: relative;
	}
	.iq-top-navbar {
		.iq-sub-dropdown {
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 100%;
		}
		.iq-search-bar{
			width: 100%;
		}
		.iq-user-dropdown {
			width: 300px;
		}
	}
	.navbar-list {
		li {
			position: static;
			>a.language-title {
				font-size: 0;
			}
		}
	}

}
@media(max-width:479px) {
	.navbar-list {
		li {
			>a {
				padding: 0 10px;
				>img {
					width: 40px;
					height: 40px;
					min-width: 40px;
				}
			}
		}
	}

}
@media (max-width: 380px) {
	.header-logo {
		h4 {
			display: none;
		}
	}
	.sidebar-main {
		.menu-horizontal {
			.header-logo {
				h4 {
					display: block;
				}
			}
		}
	}
	
}
