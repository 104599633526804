.media-support {
	display: inline-block;
	width: 100%;
}
.media-support-user-img {
	img {
		height: 60px;
	}
}
.media-support-header {
	display: flex;
	align-items: flex-start;
}
.media-support-info {
	flex: 1;
}
.projects-media {
	img {
		height: 60px;
	}
}
.iq-media-group {
	.iq-media {
		margin-left: -20px;
		position: relative;
		z-index: 0;
		@if $enable-transitions == true {
			@include transition-mixin(all 0.3s ease-in-out);
		}
		&:hover {
			z-index: 9;
		}
		img {
			border: 2px solid $border-color;
		}
		&:first-child {
			margin-left: 0;
		}
	}
	.iq-media.border {
		border: 2px solid $border-color;
	}
}
.iq-media {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	&:hover {
		text-decoration: none;
	}
}
.media-pl {
	text-align: center;
}
