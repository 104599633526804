.iq-example-row {
	.row {
		>.col {
			padding-top: .75rem;
			color: $body-color;
			padding-bottom: .75rem;
			background-color: rgba(86, 61, 124, .15);
			border: 1px solid rgba(86, 61, 124, .2);
		} 
		>[class^=col-] {
			padding-top: .75rem;
			color: $body-color;
			padding-bottom: .75rem;
			background-color: rgba(86, 61, 124, .15);
			border: 1px solid rgba(86, 61, 124, .2);
		}
	}
}
.iq-example-row-flex-cols {
	.row {
		margin-bottom: 15px;
		min-height: 10rem;
		background-color: rgba(255, 0, 0, .1);
	}
}
