// header style

.l-horizontal {
	.content-page {
		margin-left: 0;
	}
	.iq-top-navbar {
		box-shadow: none;
		width: 100%;
		padding: 0;
	}
}

.hospi-wrapper {
	.navbar-breadcrumb{
		h4{
			color: $white;
		}
	}
	z-index: 1;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 300px;
		background: $primary;
		border-radius: var(--border-radius-bottom);
		z-index: -1;
	}
	
}
.bg-h-full {
	.navbar-list {
		li {
			>a {
				i {
					color: $white;
				}
			}
		}
	}
	.iq-top-navbar {
		background: transparent;
	}
	.iq-top-navbar.fixed {
		background: $primary;
		-webkit-animation-name: animationFade;
		-o-animation-name: animationFade;
		animation-name: animationFade;
		-webkit-animation-duration: 0.7s;
		-o-animation-duration: 0.7s;
		animation-duration: 0.7s;
		-webkit-animation-fill-mode: both;
		-o-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-box-shadow: 0px 2px 25px 0px rgba(45, 69, 95, 0.06);
		-moz-box-shadow: 0px 2px 25px 0px rgba(45, 69, 95, 0.06);
		box-shadow: 0px 2px 25px 0px rgba(45, 69, 95, 0.06);
		.navbar {
			padding: 0 !important;
		}
		.h-logo-ic {
			width: 150px;
		}
	}
	.iq-top-navbar.header-style-3.fixed {
		background: $primary-dark;
	}
	.navbar-light {
		.navbar-toggler {
            i {
				color: $white;
			}
		}
	}
}

.header-style-1 {
	.iq-navbar-logo {
	>a {
		display: flex;
	}
}
	.main-circle {
		i {
			color: $white;
		}
	}
}
.th-line {
	color: $white;
}

.bg-h-full {
	.iq-top-navbar.fixed {
		.navbar {
			padding: 15px 0 !important;
		}
	}
}


// content
body.hospi-wrapper {
	.hospi-row-top{
		margin-top: 50px;
	}
	.iq-footer {
		margin-left: 0;
		width: 100%;
	}
}

#hospital-chart-03 {
    min-height: 340px !important;
}

.bg-h-full {
	.nav-item {
		span.dots {
			background: $white;
		}
	}
}
.data-scrollbar.menu-scroll {
	height: 300px;
}
.data-scrollbar-menu.menu-scroll {
	height: 300px;
}
.hospi-content {
	.card-header-toolbar {
		.dropdown-toggle {
			i {
				vertical-align: middle;
			}
		}
	}
	.navbar-breadcrumb {
		h5 {
			color: $white;
		}
		li {
			color: $white;
		}
		a {
			color: $white;
		}
	}
	.breadcrumb-item+.breadcrumb-item {
		&::before {
			color: $white;			
		}
	}
}
.doc-img {
	padding: 8px;
	border: 2px solid transparent;
}
.doc-img.avail {
	border: 2px solid $info;
}
.d-avail-text {
	i {
		font-size: 9px;
	}
}
.doc-social {
	i {
		margin-right: 8px;
		font-size: 20px;
	}
}
.text-primary-dark {
	color: $primary-dark;
}
#hospital-chart-01 {
	height: 320px;
}
#hospital-chart-03 {
	.apexcharts-legend.position-bottom.center {
		justify-content: flex-start;
	}
	.apexcharts-legend.position-top.center {
		justify-content: flex-start;
	}
}

.dr-pro {
	@if $enable-rounded == true {
        @include radius-mixin($border-radius-right);
    }
}
.op-count {
    @if $enable-rounded == true {
        @include radius-mixin($border-radius);
    }
}

.inln-date {
	.flatpickr-calendar {
		box-shadow: none;
		margin: 0 auto;
		padding: 0;
	}
	.dayContainer {
		width: 100%;
		min-width: auto;
	}
	.flatpickr-days {
		width: 100%;
	}
}
.doc-social.h-doc-s {
	i {
		font-size: 16px;
	}
}
.iq-icon-box.ex-box {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
}
.l-horizontal {
	.header-style-1 {
		.search-content {
			display: none;
		}
	}
}
.header-style-1.iq-top-navbar {
	line-height:normal;
	.wrapper-menu {
		color: $white;
	}
}
.card-overlay-image {
	z-index: 1;
	.overlay-image {
		position: absolute;
		top: 0;
		left: auto;
		right: 0;
		bottom: 0;
		z-index: -1;
		opacity: 0.1;
	}
}
.ratting-icons {
    font-size: 45px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

// responcive
@media (min-width: 1300px){
	.hospi-wrapper{
		.iq-navbar-logo{
			display: flex !important;
		}
	}
.header-style-1{
	.menu-horizontal {
		.iq-sidebar-menu {
			.side-menu {
				>li {
					&:hover {
						>a {
							color: $white;
						}
					}
					>a{
						color: $white;
					}
				}
				li.active {
			>a {
				>i.iq-arrow-left {
					color: $white;
				}
			}
		}
			}
		}
	}
}
.h-collapse {
	.iq-sidebar-logo {
		display: none !important;
	}
}
.dev-toggle {
	display: none !important;
}


}
@media (max-width: 1299px){
	.header-style-1.iq-top-navbar{
		.iq-sidebar-logo{
			padding: 15px 0px;
			.wrapper-menu {
				color: $primary;
				margin: 0 0px 0 0;
			}
		}
		
	}
	.hospi-wrapper{
		.l-horizontal{
			.iq-sidebar-menu {
				.side-menu {
					li {
						a {
							padding: 15px 0px 15px 0px;
						}
					}
				}
			}
			
		}
	}
	
	
}
@media(max-width:991px){
    .l-horizontal {
		.header-style-1 {
			.search-content {
				display: block;
			}
		}
	}
.hospi-wrapper {
	.navbar-list {
		li {
			>a {
				i {
					color: $primary;
				}
			}
		}
	}

}
.header-style-1.iq-top-navbar {
	padding: 0 ;
}
.welcome-content {
    display: none !important;
}

}

// animation

@-webkit-keyframes animationFade {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-o-keyframes animationFade {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		transform: none;
	}
}
@keyframes animationFade {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		transform: none;
	}
}


@media(max-width:578px){
	.hospi-wrapper  { 
		#hospital-chart-03{
			.apexcharts-legend {
				bottom: 0px !important;
			}
		}
		.container-fluid.container-md.iq-navbar-custom{
			padding: 0px !important;
		}
	}

}

@media(max-width:320px){
		.hospi-wrapper  { 
			.flatpickr-innerContainer{
				padding-right: 40px;
		}
		.flatpickr-current-month{
			 left: 6.5%;
			}
		.flatpickr-months {
			.flatpickr-next-month.flatpickr-next-month {
					padding-right: 46px;
				}
				.flatpickr-prev-month.flatpickr-next-month {
					padding-right: 46px;
				}
			}
		.bg-h-full {
			.iq-top-navbar.fixed {
				.navbar {
					padding: 0px !important;
				}
			}
		}
	}
}

.hospi-wrapper {
	.vactormap-height {
		width: 100%;
		height: 185px;
	}
	.jvectormap-container {
		background-color: $primary !important;
	}
}
	
