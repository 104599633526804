//my team

.list-style-1{
    list-style-type: none;
    padding: 0;

    .list-item {        
        .list-style-action{
            a{
                height: 32px;
                width: 32px;
                border-radius: 50%;
                color: #cecfda;
                font-size: .9rem;
                transition: all .3s;
            }
        } 
        a{
            cursor: pointer;
            text-decoration: none;
        }
        .list-style-action{
            a:hover{
                color: #a2a5b9;
                background: #f5f5f5;
            }
        }
        .avatar{
            .avatar-img{            
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 50px;
                min-width: 50px;
                height: 50px;
                object-fit: cover;
                img{
                    height: 50px;
                    width: 50px;
                }
            }
        } 
        @each $key, $value in $list-colors {
            .avatar .avatar-#{$key}
            {
                background: lighten($value,10%);
                span{
                    color: darken($value,20%);
                }
            }
        }
    }
}